import React from "react";
import "../scss/Footer.scss";
import { NavLink } from "react-router-dom";
import ArrowUpSVG from "../assets/svg/ArrowUpSVG";
import EmailSVG from "../assets/svg/EmailSVG";
import LinkedInSVG from "../assets/svg/LinkedInSVG";
import YoutubeSVG from "../assets/svg/YoutubeSVG";

export default function Footer() {
    return (
        <footer>
            <div className="container footer-container">
                <div className="footer-top">
                    <NavLink to="/" className="footer-item">
                        Work
                    </NavLink>
                    <NavLink to="about" className="footer-item">
                        About
                    </NavLink>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href="#"
                        className="footer-item"
                        aria-label="return to top of page"
                    >
                        Return to Top <ArrowUpSVG />
                    </a>
                </div>
                <div className="footer-bottom">
                    <div className="social-links footer-item">
                        <a
                            className="social-link-item"
                            href="mailto: jonpalmer677@gmail.com"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Email me"
                        >
                            <EmailSVG />
                        </a>
                        <a
                            className="social-link-item"
                            href="http://www.linkedin.com/in/jonpalmer2/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Visit my LinkedIn"
                        >
                            <LinkedInSVG />
                        </a>
                        <a
                            className="social-link-item"
                            href="http://www.youtube.com/playlist?list=PLmVcD3acQ6iHN72akVsc0Uu-vhPsAcJUL"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Email me"
                        >
                            <YoutubeSVG />
                        </a>
                    </div>
                    <p className="footer-item">Copyright 2022 &copy;</p>
                </div>
            </div>
        </footer>
    );
}
