import React from "react";
import "../scss/ContactForm.scss";
import NeonHeader from "./NeonHeader";
import Button from "./Button";

export default function ContactForm() {
    return (
        <section className="contact-section container">
            <div id="contact" className="text-center">
                <NeonHeader text="Get in touch!" />
                <form method="post" action="https://formspree.io/f/mbjwqggw">
                    <div className="">
                        <div className="top-row">
                            <div className="contact-user-info">
                                <label className="contact-label" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </div>
                            <div className="contact-user-info">
                                <label
                                    className="contact-label"
                                    htmlFor="floatingEmail"
                                >
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="floatingEmail"
                                />
                            </div>
                        </div>
                        <div className="contact-user-info">
                            <label
                                className="contact-label"
                                htmlFor="contactMessage"
                            >
                                Message
                            </label>
                            <textarea
                                className="form-control"
                                name="message"
                                id="contactMessage"
                                rows="6"
                            ></textarea>
                        </div>
                    </div>
                    <Button
                        className="submit-btn btn"
                        type="submit"
                        id="submit"
                        value="Send Message"
                        text="Send Message"
                    />
                </form>
            </div>
        </section>
    );
}
