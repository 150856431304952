import React from "react";
import "../scss/About.scss";
import JonHeadshot from "../assets/img/jon-headshot.jpg";
import Resume from "../assets/img/resume.pdf";
import ContactForm from "../components/ContactForm";
// import Footer from "../components/Footer";

export default function About() {
  return (
    // <div className="about-page">
    <div className="about">
      {/* <section className="hero"> */}
      <div className="about-container container">
        <div className="about-header">
          <img
            src={JonHeadshot}
            alt="Jonathan Palmer smiling to camera in black glasses and a blue button up shirt"
            className="abt-header-img"
          />
          <p className="abt-header-text">
            <span className="hi-jon">Welcome to my site!</span>
            <br></br>I’m Jonathan Palmer, a multimedia storyteller. I use my
            proficiencies in video, audio and photography to tell compelling
            stories. I'm currently a video producer for Forbes Media, where I
            produce serialized content as well as sponsored and editorial video
            content for our social media platforms and website.
          </p>
        </div>
      </div>
      {/* </section> */}
      <a
        id="resume"
        href={Resume}
        download=""
        className="resume-btn btn container"
      >
        Download My Resume
      </a>
      <ContactForm />
      {/* </section> */}
      {/* <Footer /> */}
    </div>
    // </div>
  );
}
