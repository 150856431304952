import React from "react";
import "../scss/Home.scss";
import NeonHeader from "../components/NeonHeader";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <div className="home">
            <section className="hero">
                <div className="hero-textbox container">
                    <h1 className="logo">
                        <b>
                            J<span>on</span>
                            <span>a</span>
                            than P<span>a</span>
                            lmer.
                        </b>
                    </h1>
                    <p>Editor | Producer | Director</p>
                </div>
            </section>
            <section id="work" className="work-section">
                <div className="container work-container">
                    <NeonHeader text="My Work" />
                    <div className="work-example">
                        <h4>
                            <span className="work-title">Mini Docs</span> |
                            Producer, Director
                        </h4>
                        <div className="video-wrapper">
                            <iframe
                                src="https://www.youtube.com/embed/iKePJ1d9FVU"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="work-example">
                        <h4>
                            <span className="work-title">Forbes Priceless</span>{" "}
                            | Co-Producer, Director
                        </h4>
                        <div className="video-wrapper">
                            <iframe
                                src="https://www.youtube.com/embed/9Rg5Wnu6uWw"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="work-example">
                        <h4>
                            <span className="work-title">Forbes Flash</span> |
                            Producer, Editor
                        </h4>
                        <div className="video-wrapper">
                            <iframe
                                src="https://www.youtube.com/embed/5ohNxv5XJL0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <a
                        href="https://youtube.com/playlist?list=PLmVcD3acQ6iHN72akVsc0Uu-vhPsAcJUL"
                        target="_blank"
                        rel="noreferrer"
                        className="playlist-btn btn"
                    >
                        View My Full Video Playlist
                    </a>
                </div>
            </section>
            <Footer />
        </div>
    );
}
