import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../scss/NavBar.scss";

export default function NavBar() {
    const [active, setActive] = useState("nav-menu");

    const [toggleIcon, setToggleIcon] = useState("nav-toggler");

    const navToggle = () => {
        active === "nav-menu"
            ? setActive("nav-menu nav-active")
            : setActive("nav-menu");

        // TogglerIcon
        toggleIcon === "nav-toggler"
            ? setToggleIcon("nav-toggler toggle")
            : setToggleIcon("nav-toggler");
    };

    return (
        <nav className="nav">
            <NavLink to="/" className="brand nav-link">
                Jonathan Palmer
            </NavLink>
            <ul className={active}>
                <li className="nav-item">
                    <NavLink onClick={navToggle} className="nav-link" to="/">
                        Home
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        onClick={navToggle}
                        className="nav-link"
                        to="/about"
                    >
                        About
                    </NavLink>
                </li>
            </ul>
            <div onClick={navToggle} className={toggleIcon}>
                <div className="line-1"></div>
                <div className="line-2"></div>
                <div className="line-3"></div>
            </div>
        </nav>
    );
}
