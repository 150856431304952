import React from "react";
import "../scss/Button.scss";

export default function Button(props) {
  return (
    <button
      className={props.className}
      type={props.type}
      id={props.id}
      value={props.value}
    >
      {props.text}
    </button>
  );
}
