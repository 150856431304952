import "./scss/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./routes/Home";
import About from "./routes/About";
import Footer from "./components/Footer";

function App() {
    return (
        <Router>
            <div className="App">
                <div className="site-wrapper">
                    <NavBar />
                    <Routes>
                        <Route path="/about" element={<About />} />
                        <Route path="/" element={<Home />} />
                    </Routes>
                    <Footer />
                </div>
            </div>
        </Router>
    );
}

// logic to handle illumination of NeonHeader components onScroll
const neonHeaders = document.querySelectorAll(".neon-header");

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            entry.target.classList.toggle(
                "neon-header-glow",
                entry.isIntersecting
            );
        });
    },
    {
        threshold: 1,
    }
);

neonHeaders.forEach((nHeader) => {
    observer.observe(nHeader);
});

export default App;
